import React from "react"
import { graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo";

import SinglePost from '../components/single-page-service/index'


const SP = ({data}) => {
  let d = data.strapiSpecialties;
  return(
    <Layout>
      <SEO title={`tets`}/>
      <SinglePost data={d} cat={`specialty`}/>  
    </Layout>
  )
}

export default SP

export const query = graphql`
  query SpecialtyTemplate($slug: String!) {
    strapiSpecialties(slug: { eq: $slug }) {
      id
      name
      excerpt
      content
      slug
      feature_image {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  }
` 

